import { render, staticRenderFns } from "./Admission.vue?vue&type=template&id=1d9b75a9&scoped=true&"
var script = {}
import style0 from "./Admission.vue?vue&type=style&index=0&id=1d9b75a9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d9b75a9",
  null
  
)

export default component.exports